import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const { General } = require("../networking");

// options

export const mostExpPlayer = createAsyncThunk(
  "General/mostExpPlayer",
  async (sessionId) => {
    const response = await General.mostExpPlayer(sessionId);
    return response;
  }
);

export const lastSoldPlayer = createAsyncThunk(
  "General/lastSoldPlayer",
  async (sessionId) => {
    const response = await General.lastSoldPlayer(sessionId);
    return response;
  }
);

export const activeBidding = createAsyncThunk(
  "General/activeBidding",
  async (sessionId) => {
    const response = await General.activeBidding(sessionId);
    return response;
  }
);

export const blockStatus = createAsyncThunk(
  "general/blockStatus",
  async (sessionId) => {
    const response = await General.blockStatus(sessionId);
    return response;
  }
);

export const purseremaining = createAsyncThunk(
  "General/purseremaining",
  async (sessionId) => {
    const response = await General.purseremaining(sessionId);
    return response;
  }
);

export const activeBlock = createAsyncThunk(
  "general/activeBlock",
  async (sessionId) => {
    const response = await General.activeBlock(sessionId);
    return response;
  }
);

export const BiddingBlocks = createAsyncThunk(
  "general/BiddingBlocks",
  async (sessionId) => {
    const response = await General.BiddingBlocks(sessionId);
    return response;
  }
);

const generalSlice = createSlice({
  name: "general",
  initialState: {
    status: "",
    mostExpPlayer: {},
    activeBlock: {},
    biddingBlocks: [],
    lastSoldPlayer: {},
    purseremaining: {},
    activeBidding: {},
    blockStatus: "",
    errorString: "",
    error: "",
  },
  reducers: {
    // logout: (state, action) => {},
  },

  extraReducers: {
    [mostExpPlayer.pending]: (state) => {
      state.status = "waiting";
    },
    [mostExpPlayer.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.mostExpPlayer = data;
        state.status = "Fetched Most Expensive Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [mostExpPlayer.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [lastSoldPlayer.pending]: (state) => {
      state.status = "waiting";
    },
    [lastSoldPlayer.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.lastSoldPlayer = data;
        state.status = "Fetched Most Expensive Data!";
      } else if (statusCode !== 200) {
        state.errorString = "Some Error Occured!";
        state.status = "failed";
      } else {
        state.errorString = "Login first";
      }
    },
    [lastSoldPlayer.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [activeBidding.pending]: (state) => {
      state.status = "waiting";
    },
    [activeBidding.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200) {
        state.activeBidding = data;
        state.status = "Fetched Active Bidding Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [activeBidding.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [blockStatus.pending]: (state) => {
      state.status = "waiting";
    },
    [blockStatus.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.blockStatus = data?.status;
        state.status = "Fetched Active Bidding Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [blockStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [purseremaining.pending]: (state) => {
      state.status = "waiting";
    },
    [purseremaining.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.purseremaining = data;
        state.status = "Fetched Active Block Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [purseremaining.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [activeBlock.pending]: (state) => {
      state.status = "waiting";
    },
    [activeBlock.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.activeBlock = data;
        state.status = "Fetched Active Block Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [activeBlock.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
    [BiddingBlocks.pending]: (state) => {
      state.status = "waiting";
    },
    [BiddingBlocks.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data) {
        state.biddingBlocks = data;
        state.status = "Fetched Bidding Blocks Data!";
      } else if (statusCode !== 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [BiddingBlocks.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
    },
  },
});

export const selectstatus = (state) => state.general.status;
export const selectmostExpPlayer = (state) => state.general.mostExpPlayer;
export const selectbiddingBlocks = (state) => state.general.biddingBlocks;
export const selectactiveBlock = (state) => state.general.activeBlock;
export const selectLastSoldPlayer = (state) => state.general.lastSoldPlayer;
export const selectactiveBidding = (state) => state.general.activeBidding;
export const selectBlockStatus = (state) => state.general.blockStatus;
export const selectPurseRemaining = (state) => state.general.purseremaining;
export const selecterrorString = (state) => state.general.errorString;
export const selecterror = (state) => state.general.error;

export default generalSlice.reducer;
