import "./index.scss";
import Navbar from "../../components/navbar";
import DialogBox from "../../components/dialogBox";
import { Col, Container, Row, Table } from "react-bootstrap";
import LastSoldPlayer from "../../components/lastSoldPlayer";
import MostExpPlayer from "../../components/mostExpPlayer";
import PurseRemaining from "../../components/purseRemaining";
import PlayerDetails from "../../components/playerDetails";
import PlayerBid from "../../components/playerBid";
import TeamBidding from "../../components/teamBidding";
import { useDispatch, useSelector } from "react-redux";
import { selectShowLoading } from "../../store/features/auctionSlice";
import Modal from "../../components/modal";
import RoundButton from "../../components/roundButton";
import TeamCards from "../../components/teamCards";
import ViewPlayers from "../../components/viewPlayers";
import {
  activeBlock as activeBlockk,
  BiddingBlocks,
  createBid,
  getProfile,
  logout,
  selectactiveBlock,
  selectbiddingBlocks,
  selectinfo,
  selectisLoggedIn,
  selectActiveSession,
  selectstatus,
  getActiveSession,
  startBlock,
} from "../../store/features/curatorSlice";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import Cbtn from "../../components/commonbutton/cbtn";
import { Link } from "react-router-dom";

const PlayerList = ({ socket }) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [key, setKey] = useState("elite");
  let isLoggedin = useSelector(selectisLoggedIn);
  let activeBlock = useSelector(selectactiveBlock);
  let biddingBlocks = useSelector(selectbiddingBlocks);
  let info = useSelector(selectinfo);
  let status = useSelector(selectstatus);
  let session = useSelector(selectActiveSession);

  useEffect(() => {
    dispatch(activeBlockk());
    dispatch(BiddingBlocks());
    dispatch(getActiveSession());
  }, [isLoggedin]);

  useEffect(() => {
    if (!isLoggedin) {
      // navigate("/");
    }
  }, [isLoggedin]);

  // console.log(biddingBlocks);

  const OnStartBlock = async () => {
    if (session?.sessionId) {
      let res = await dispatch(startBlock(key));
      if (res?.payload?.statusCode === 200) {
        socket.emit("start-block", {
          ...biddingBlocks[key][0],
          sessionId: session?.sessionId,
        });
        socket.on("block-started-curator", () => {
          navigate("/curator/auction");
        });
      }
    }
  };

  useEffect(() => {
    if (info._id) {
      socket.emit("curator-entry", info._id);
    }
  }, [info._id]);

  return (
    <>
      {!isLoggedin ? (
        <>
          Not Authenticated. <Link to="/curator/login">Login</Link>
        </>
      ) : (
        <>
          {activeBlock?._id && session?.sessionId && (
            <Navigate to="/curator/auction" replace />
          )}

          {/* <Modal
        show={showLoading}
        content="PLEASE WAIT... WHILE PLAYER IS BEING SELECTED"
      /> */}
          <Navbar />
          <div
            style={
              {
                // backgroundSize: "90rem",
                // height: "43rem",
              }
            }
          >
            <Container
              className="player-list-container"
              // style={{ height: "45rem" }}
            >
              <Row className="" style={{ justifyContent: "flex-end" }}>
                <Col
                  className="table"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Cbtn
                    style={{ fontSize: "1rem" }}
                    onClick={async () => {
                      let res = await dispatch(logout());
                      if (res) {
                        window.location.href = "/";
                      }
                    }}
                  >
                    LOGOUT
                  </Cbtn>
                </Col>
              </Row>
              <Row className="" style={{ justifyContent: "space-between" }}>
                <Col className="table">
                  <ViewPlayers
                    blocks={biddingBlocks}
                    keyState={{ key, setKey }}
                  />
                </Col>
              </Row>

              <Row
                className=""
                style={{ padding: "1rem 0", justifyContent: "center" }}
              >
                {session?.sessionId ? (
                  <>
                    {biddingBlocks?.[key]?.[0]?.status?.toLowerCase() ===
                      "ready" && (
                      <RoundButton
                        type="button"
                        className="start-btn"
                        onClick={OnStartBlock}
                      >
                        START
                      </RoundButton>
                    )}
                    {biddingBlocks?.[key]?.[0]?.status?.toLowerCase() ===
                      "stopped" && (
                      <RoundButton
                        type="button"
                        className="start-btn resume"
                        onClick={OnStartBlock}
                      >
                        RESUME
                      </RoundButton>
                    )}
                    {biddingBlocks?.[key]?.[0]?.status?.toLowerCase() ===
                      "finished" && (
                      <RoundButton
                        type="button"
                        // disabled
                        className="start-btn finished"
                        onClick={() => {}}
                      >
                        FINISHED
                      </RoundButton>
                    )}
                  </>
                ) : (
                  <Col
                    style={{
                      fontSize: "2rem",
                      justifyContent: "center",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    Please Wait For Admin to Start the Auction
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default PlayerList;
