import { combineReducers } from "redux";
import auctionSlice from "./features/auctionSlice";
import bidderSlice from "./features/bidderSlice";
import curatorSlice from "./features/curatorSlice";
import generalSlice from "./features/generalSlice";

export default combineReducers({
  curator: curatorSlice,
  auction: auctionSlice,
  bidder: bidderSlice,
  general: generalSlice,
});
