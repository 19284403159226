import "./index.scss";
import background1 from "../../resources/images/background-plain.png";
import Navbar from "../../components/navbar";
import DialogBox from "../../components/dialogBox";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectShowLoading } from "../../store/features/auctionSlice";
import Modal from "../../components/modal";
import RoundButton from "../../components/roundButton";
import TeamCards from "../../components/teamCards";
import ViewPlayers from "../../components/viewPlayers";
import {
  activeBlock as activeBlockk,
  BiddingBlocks,
  selectactiveBlock,
  selectbiddingBlocks,
  selectisLoggedIn,
  selectstatus,
} from "../../store/features/curatorSlice";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PlayerListActive = () => {
  let dispatch = useDispatch();
  let [key, setKey] = useState("elite");
  let isLoggedin = useSelector(selectisLoggedIn);
  let activeBlock = useSelector(selectactiveBlock);
  let biddingBlocks = useSelector(selectbiddingBlocks);
  let status = useSelector(selectstatus);

  useEffect(() => {
    dispatch(activeBlockk());
    dispatch(BiddingBlocks());
  }, []);

  useEffect(() => {
    if (status === "Started Block!") {
      window.location.reload();
    }
  }, [status]);

  return (
    <>
      {!isLoggedin ? (
        <>Not Logged in</>
      ) : (
        <>
          {/* <Modal
        show={showLoading}
        content="PLEASE WAIT... WHILE PLAYER IS BEING SELECTED"
      /> */}
          <Navbar />
          <div
            style={{
              background: `url(${background1}) center center/cover`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              // backgroundSize: "90rem",
            }}
          >
            <Container
              className="player-list-container"
              style={{ height: "45rem" }}
            >
              <Row className="last-sold-player" style={{ marginTop: "1rem" }}>
                <Link
                  to="/curator/auction"
                  style={{
                    textDecoration: "none",
                    font: "inherit",
                    color: "inherit",
                  }}
                >
                  <RoundButton type="button">GO BACK</RoundButton>
                </Link>
              </Row>
              <Row className="" style={{ justifyContent: "space-between" }}>
                <Col className="table">
                  <ViewPlayers
                    blocks={biddingBlocks}
                    keyState={{ key, setKey }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default PlayerListActive;
