import instance from "./instance";

const login = async (data, token) => {
  let res = await instance({
    method: "post",
    url: "login",
    data,
  });
  return res.data;
};

const getProfile = async (token) => {
  let { data } = await instance({
    method: "get",
    url: "getProfile",
  });
  return data;
};

const editProfile = async (data, token) => {
  let res = await instance({
    method: "put",
    url: "editProfile",
    data,
  });
  return res.data;
};

const logout = async (token) => {
  let res = await instance({
    method: "post",
    url: "logout",
  });
  return res.data;
};

// options

const boughtPlayers = async (token) => {
  let res = await instance({
    method: "get",
    url: "boughtPlayers",
    data: { sivbui: "auivb" },
  });
  return res.data;
};

const lastYearPlayer = async (token) => {
  let res = await instance({
    method: "get",
    url: "lastYearPlayer",
  });
  return res.data;
};

const franchises = async (token) => {
  let res = await instance({
    method: "get",
    url: "franchises",
  });
  return res.data;
};

const bid = async (data, token) => {
  // console.log(data);
  let res = await instance({
    method: "put",
    url: "bid",
    data: data,
  });
  return res.data;
};

const rtm = async (data, token) => {
  // console.log(data);
  let res = await instance({
    method: "put",
    url: "rtm",
    data: data,
  });
  return res.data;
};

const purseremaining = async (token) => {
  let res = await instance({
    method: "get",
    url: "purseremaining",
  });
  return res.data;
};

const activeBidding = async (token) => {
  let res = await instance({
    method: "get",
    url: "activeBidding",
  });
  console.log(res.data);

  return res.data;
};

const getSession = async (data, token) => {
  let res = await instance({
    method: "get",
    url: "session",
    data,
  });
  return res.data;
};

const BiddingBlocks = async (token) => {
  let res = await instance({
    method: "get",
    url: "biddingBlocks",
  });
  return res.data;
};

const activeBlock = async (token) => {
  let res = await instance({
    method: "get",
    url: "activeBlock",
  });
  return res.data;
};

const blockStatus = async (token) => {
  let res = await instance({
    method: "get",
    url: "blockStatus",
  });
  return res.data;
};

export default {
  login,
  getProfile,
  editProfile,
  rtm,
  blockStatus,
  activeBlock,
  BiddingBlocks,
  logout,
  boughtPlayers,
  franchises,
  bid,
  lastYearPlayer,
  getSession,
  purseremaining,
  activeBidding,
};
