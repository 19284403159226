import "./index.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/navbar";
import {
  boughtPlayers,
  getProfile,
  activeBlock as activeBlockk,
  BiddingBlocks,
  blockStatus,
  selectbiddingBlocks,
  purseremaining as getPurse,
  selectBidderProfile,
  selectactiveBidding,
  activeBidding,
  selectActiveSession,
  getActiveSession,
} from "../../store/features/bidderSlice";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import RoundButton from "../../components/roundButton";
import backArrow from "../../resources/images/Arrow 1.png";
import background1 from "../../resources/images/background-plain.png";
import ViewPlayers from "../../components/viewPlayers";
import Modal from "../../components/modal";
import gavel from "../../resources/images/gavel2.png";
import depressed from "../../resources/images/depressed.png";

const AllPlayersListGeneral = ({ socket }) => {
  let [key, setKey] = useState("elite");
  let biddingBlocks = useSelector(selectbiddingBlocks);
  let [showLoading, setShowLoading] = useState(false);
  let [counter, setCounter] = useState(1);
  let [content, setContent] = useState("");
  let profile = useSelector(selectBidderProfile);
  let activePlayer = useSelector(selectactiveBidding);
  let session = useSelector(selectActiveSession);
  let dispatch = useDispatch();

  useEffect(() => {
    if (profile?._id && session?.sessionId) {
      socket.emit("bidder-entry", profile._id);
      socket.on("bidder-connected", () => {
        socket.emit("join-bidder-session", session?.sessionId);
      });
    }
  }, [session]);

  useEffect(() => {
    dispatch(boughtPlayers());
    dispatch(activeBidding());
    dispatch(getActiveSession());
    dispatch(activeBlockk());
    dispatch(BiddingBlocks());
  }, []);

  return (
    <>
      <Modal show={showLoading} content={content} />
      <Navbar />
      <div
        style={{
          background: `url(${background1}) center center/cover`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          // backgroundSize: "90rem",
          height: "43rem",
        }}
      >
        <Container
          className="player-list-container"
          style={{ height: "45rem" }}
        >
          <Row className="" style={{ justifyContent: "space-between" }}>
            <Col className="table">
              <ViewPlayers blocks={biddingBlocks} keyState={{ key, setKey }} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AllPlayersListGeneral;
