import "./index.scss";

import React, { useState } from "react";
import DialogBox from "../dialogBox";
import { Col, Row } from "react-bootstrap";

// import tendua from "../../resources/images/tendua.png";
import { useSelector } from "react-redux";
import {
  selectIsSold,
  selectLoadingStatus,
  selectSellingStatus,
} from "../../store/features/auctionSlice";

import gavel from "../../resources/images/gavel.png";

const PlayerBid = ({ isMobile, player, activeBid, owner }) => {
  let [src, setSrc] = useState(`/players/${player.image}`);
  let img = new Image();
  img.onerror = function () {
    setSrc("/players/genericplayer.png");
  };
  img.src = src;
  let isSold = useSelector(selectIsSold);
  let sellingStatus = useSelector(selectSellingStatus);
  let loadingStatus = useSelector(selectLoadingStatus);
  return (
    <>
      <Row
        className="dialog-box vertical"
        style={{
          position: "relative",
          minWidth: isMobile && "0",
          width: "9rem",
        }}
      >
        {!(loadingStatus === "fetching") && (
          <Col
            className="box tenduaImg big"
            style={{
              //   backgroundImage: `url(${tendua})`,
              //   backgroundSize: isMobile && "8rem",
              minHeight: isMobile && "8rem",
              maxHeight: isMobile && "8rem",
            }}
          >
            {player?.image && (
              <img
                // placeholder="/players/genericplayer.png"
                src={src}
                style={{
                  // left: "-14px",
                  // top: -38,
                  zIndex: "0",
                  // position: "absolute",
                  maxHeight: "8rem",
                  minHeight: "8rem",
                }}
              />
            )}
          </Col>
        )}

        {isSold ? (
          <Col className="box white big" style={{ backgroundColor: "#0bd18a" }}>
            <Row className="sold">
              <Col
                lg={5}
                className="gavel"
                style={{ backgroundImage: `url(${gavel})` }}
              >
                {/* <img src={gavel} style={{ height: "6rem" }}></img> */}
              </Col>
              <Col>
                <div className="sold-title">SOLD</div>
                <div className="bid-value white">
                  {player?.currentBid
                    ? player?.currentBid > 100
                      ? `${player.currentBid / 100}CR`
                      : `${player.currentBid}L`
                    : "-"}
                </div>
              </Col>
            </Row>
          </Col>
        ) : (
          <>
            {sellingStatus === "in progress" && (
              <Col
                className="box white big"
                style={{
                  minHeight: "5rem",
                  zIndex: 1,
                }}
              >
                <div
                  className="current-bid"
                  style={{
                    fontSize: "0.8rem",
                    letterSpacing: "0.3px",
                    lineHeight: "1.2rem",
                  }}
                >
                  CURRENT BID
                </div>
                <div
                  className="bid-value"
                  style={{
                    fontSize: "1rem",
                    lineHeight: "1.2rem",
                    width: "7rem",
                    color:
                      player?.currentBid === activeBid?.lastBid &&
                      player._id === activeBid?.player &&
                      player?.lastBidBy?.franchise?.toString() ===
                        owner?.Franchise?.toString()
                        ? "#00C92C"
                        : "#920202",
                    borderBottom: "1px solid #9E9DD3",
                  }}
                >
                  {player?.currentBid
                    ? player?.currentBid > 100
                      ? `${player.currentBid / 100}CR`
                      : `${player.currentBid}L`
                    : "-"}
                </div>

                <div
                  className="current-bid"
                  style={{
                    fontSize: "0.8rem",
                    letterSpacing: "0.3px",
                    lineHeight: "1.2rem",
                  }}
                >
                  BID BY
                </div>
                <div
                  className="bid-value"
                  style={{
                    letterSpacing: "0.1px",
                    fontSize: "1rem",
                    lineHeight: "1.2rem",
                  }}
                >
                  {player?.lastBidBy?.team
                    ? player?.lastBidBy?.team?.split(" ")[0]?.toUpperCase()
                    : "-"}
                </div>
              </Col>
            )}
            {sellingStatus === "unsold" && (
              <Col className="box white big unsold">
                <div className="unsold">UNSOLD</div>
                {/* <div className="bid-value">₹100</div> */}
              </Col>
            )}
            {(sellingStatus === "no bids" || loadingStatus === "fetching") && (
              <Col className="box white big">
                <div className="current-bid">CURRENT BID</div>
                <div className="bid-value">-</div>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default PlayerBid;
