import "./index.scss";
import background1 from "../../resources/images/background-plain.png";
import Navbar from "../../components/navbar";
import DialogBox from "../../components/dialogBox";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import LastSoldPlayer from "../../components/lastSoldPlayer";
import MostExpPlayer from "../../components/mostExpPlayer";
import PlayerDetails from "../../components/playerDetails";
import PlayerBid from "../../components/playerBid";
import TeamBidding from "../../components/teamBidding";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal";
import RoundButton from "../../components/roundButton";
import { useEffect, useState } from "react";
import {
  activeBidding,
  activeBlock as activeBlockk,
  bidStatus,
  createBid,
  finishBlock,
  franchises,
  getActiveSession,
  getProfile,
  lastSoldPlayer,
  mostExpPlayer,
  resetBid,
  selectactiveBidding,
  selectactiveBlock,
  selectActiveSession,
  selectFranchisesData,
  selectinfo,
  selectisLoggedIn,
  selectLastSoldPlayer,
  selectmostExpPlayer,
  selectstatus,
  setPlayerSold,
  setPlayerSoldCustom,
  setPlayerUnsold,
  startBidding,
  stopBidding,
  stopBlock,
} from "../../store/features/curatorSlice";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import { colors } from "@mui/material";
import { MarginRounded } from "@mui/icons-material";

const CuratorPage = ({ socket }) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let [TeamOptions, setTeamOptions] = useState([]);
  let [currentBid, setCurrentBid] = useState("");
  let [teamSelection, setTeamSelection] = useState({ option: "", value: "" });
  let [showLoading, setShowLoading] = useState(false);
  let [showEditBid, setShowEditBid] = useState(false);
  let [bidStopped, setBidStopped] = useState(false);
  let [counter, setCounter] = useState(1);
  let [content, setContent] = useState(
    "PLEASE WAIT... WHILE PLAYER IS BEING SELECTED"
  );
  let isLoggedIn = useSelector(selectisLoggedIn);
  let session = useSelector(selectActiveSession);
  let info = useSelector(selectinfo);
  let activeBlock = useSelector(selectactiveBlock);
  let activePlayer = useSelector(selectactiveBidding);
  let TeamsData = useSelector(selectFranchisesData);
  let lsPlayer = useSelector(selectLastSoldPlayer);
  let msPlayer = useSelector(selectmostExpPlayer);
  let status = useSelector(selectstatus);

  useEffect(() => {
    dispatch(activeBlockk());
    dispatch(franchises());
    dispatch(lastSoldPlayer());
    dispatch(mostExpPlayer());
    dispatch(activeBidding());
    dispatch(getActiveSession());
  }, [counter]);

  useEffect(() => {
    setTeamOptions(
      TeamsData?.map((data) => ({
        option: data?.name,
        value: data?._id,
        data: data,
      }))
    );
  }, [TeamsData]);

  useEffect(() => {
    if (info._id && session?.sessionId) {
      socket.emit("curator-entry", info._id);
      socket.on("curator-connected", () => {
        socket.emit("join-curator-session", session?.sessionId);
      });
    }
  }, [info._id, session?.sessionId]);

  useEffect(() => {
    if (session?.sessionId) {
      socket.on("bid-updated", () => {
        setCounter((a) => a + 1);
        // setShowLoading(true);
        // // console.log(playerType);
        // setContent(playerType?.toUpperCase() + " BLOCK STARTED!");
        // setTimeout(() => {
        //   setShowLoading(false);
        //   setContent("PLEASE WAIT... WHILE PLAYER IS BEING SELECTED");
        // }, 3000);
      });
      socket.on("rtm-updated", () => {
        setContent(<>RTM Applied</>);
        setShowLoading(true);
        // console.log(playerType);
        setCounter((a) => a + 1);
        setTimeout(() => {
          setShowLoading(false);
          setContent("PLEASE WAIT... WHILE PLAYER IS BEING SELECTED");
        }, 1000);
      });
      socket.on("block-bidding", () => {
        // console.log(bidStopped);
        if (bidStopped)
          socket.emit("block-bidding-curator", {
            status: bidStopped,
            sessionId: session?.sessionId,
          });
      });
    }
  }, [bidStopped, session?.sessionId]);

  useEffect(() => {
    if (!bidStopped) {
      setCurrentBid(activePlayer?.currentBid);
      setTeamSelection({
        option: activePlayer?.lastBidBy?.team,
        value: activePlayer?.lastBidBy?.franchise,
      });
    }
  }, [activePlayer?.currentBid, activePlayer?.lastBidBy]);

  const onCreateBid = async () => {
    if (session?.sessionId) {
      let res = await dispatch(createBid());
      if (res?.payload?.statusCode === 200) {
        let res2 = await dispatch(activeBidding());
        if (res2?.payload?.statusCode === 200) {
          socket.emit("create-bid", {
            ...res2?.payload,
            sessionId: session?.sessionId,
          });
        }
      }
    }
  };

  const onSellPlayerBtn = async () => {
    if (window.confirm("are you sure?") && session?.sessionId) {
      let res = await dispatch(setPlayerSold());
      console.log(res);
      if (res?.payload?.statusCode === 200) {
        socket.emit("player-sold", {
          ...activePlayer,
          sessionId: session?.sessionId,
        });
        socket.on("player-sold-executed", () => {
          window.location.reload();
        });
      }
    }
  };

  const onUnSellBtn = async () => {
    let check = window.confirm("are you sure?");
    if (check && session?.sessionId) {
      let res = await dispatch(setPlayerUnsold());
      if (res?.payload?.statusCode === 200) {
        let res2 = await dispatch(activeBidding());
        if (res2?.payload?.statusCode === 200) {
          socket.emit("player-unsold", {
            ...activePlayer,
            sessionId: session?.sessionId,
          });
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
    }
  };

  const onResetBtn = async () => {
    if (window.confirm("Are you sure? this will reset the current bidding")) {
      let res = await dispatch(resetBid());
      if (res?.payload?.statusCode === 200 && session?.sessionId) {
        socket.emit("reset-bid", session?.sessionId);
        window.location.reload();
      }
    }
  };

  const onEditBidBtn = async () => {
    setShowEditBid(true);
  };

  const onFinishedBtn = async () => {
    let res = await dispatch(finishBlock());
    if (res?.payload?.statusCode === 200 && session?.sessionId) {
      let res2 = await dispatch(activeBidding());
      if (res2?.payload?.statusCode === 200 && session?.sessionId) {
        socket.emit("finish-block", {
          ...activeBlock,
          sessionId: session?.sessionId,
        });
        socket.on("block-finished-curator", () => {
          window.location.reload();
        });
      }
    }
  };

  const pauseAuctionBtn = async () => {
    let check = window.confirm("are you sure?");
    if (check && session?.sessionId) {
      let res = await dispatch(stopBlock());

      if (res?.payload?.statusCode === 200) {
        let res2 = await dispatch(activeBidding());
        console.log(res2);

        if (res2?.payload?.statusCode === 200) {
          socket.emit("pause-block", {
            ...activeBlock,
            sessionId: session?.sessionId,
          });
          socket.on("block-pause-curator", () => {
            window.location.href = "/curator/playerlist";
          });
        }
      }
    }
  };

  const onStoppedSelling = async () => {
    if (bidStopped && session?.sessionId) {
      socket.emit("resume-bidding", session?.sessionId);
      await dispatch(startBidding());
      socket.on("resume-bidding-executed", () => {
        dispatch(bidStatus({ status: !bidStopped }));
      });
      setCounter((a) => a + 1);
    } else if (session?.sessionId) {
      socket.emit("stop-bidding", session?.sessionId);
      await dispatch(stopBidding());
      socket.on("stop-bidding-executed", () => {
        dispatch(bidStatus({ status: !bidStopped }));
      });
      setCounter((a) => a + 1);
    }
    setBidStopped(!bidStopped);
  };

  const bhideFallsBtn = async () => {
    if (session?.sessionId) {
      socket.emit("bhide-falls", session?.sessionId);
      socket.on("bhide-falls-executed", () => {
        alert("Done");
      });
    }
  };

  // useEffect(() => {
  //   socket.emit("publicEntry");
  //   socket.on("connected", () => {
  //     socket.emit("join room", 35);
  //   });
  //   socket.on("block-started", (playerType) => {
  //     setShowLoading(true);
  //      // console.log(playerType);
  //     setContent(playerType?.toUpperCase() + " BLOCK STARTED!");
  //     setTimeout(() => {
  //       setShowLoading(false);
  //       setContent("PLEASE WAIT... WHILE PLAYER IS BEING SELECTED");
  //     }, 3000);
  //   });
  // }, []);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/curator/login");
    }
  }, [isLoggedIn]);

  return (
    <>
      {!isLoggedIn ? (
        <>
          Not Authenticated. <Link to="/curator/login">Login</Link>
        </>
      ) : (
        <>
          {(!activeBlock?._id || !session?.sessionId) && (
            <Navigate to="/curator/playerlist" replace />
          )}
          {session?.sessionId ? (
            <>
              <Modal show={showLoading} content={content} />
              <Modal
                show={showEditBid}
                content={
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();

                      if (
                        window.confirm("are you sure?") &&
                        session?.sessionId
                      ) {
                        let res = await dispatch(
                          setPlayerSoldCustom({
                            franchiseId: teamSelection?.value,
                            currentBid: currentBid,
                          })
                        );
                        if (res?.payload?.statusCode === 200) {
                          socket.emit("player-sold", {
                            ...activePlayer,
                            currentBid,
                            lastBidBy: {
                              team: teamSelection?.option,
                              Franchise: teamSelection?.value,
                            },
                            sessionId: session?.sessionId,
                          });
                          socket.on("player-sold-executed", () => {
                            window.location.reload();
                          });
                        }
                      }
                    }}
                  >
                    <Form.Group>
                      <Form.Label style={{ textTransform: "uppercase" }}>
                        Current Bid(IN L)
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min={activePlayer?.basePrice}
                        value={currentBid}
                        onChange={(e) => {
                          setCurrentBid(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        style={{
                          textTransform: "uppercase",
                          marginTop: "1rem",
                        }}
                      >
                        Current Team
                      </Form.Label>
                    </Form.Group>
                    <SelectLabels
                      name="Franchise"
                      // disabled={
                      //   edit?.Franchise?.name ? true : false
                      // }
                      slist={TeamOptions}
                      active={teamSelection}
                      setActive={(e) => {
                        let option = TeamOptions.find(
                          (t) =>
                            t?.value?.toString() === e.target.value?.toString()
                        );
                        setTeamSelection(option);
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                    <Button
                      type="submit"
                      className="save-model"
                      style={{
                        minWidth: "6.8rem",
                        marginLeft: "1.3rem",
                        marginTop: "2rem",
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className="discard-model"
                      style={{
                        minWidth: "6.8rem",
                        marginLeft: "1.3rem",
                        marginTop: "2rem",
                      }}
                      onClick={() => {
                        setShowEditBid(false);
                      }}
                    >
                      Discard
                    </Button>
                  </Form>
                }
              />
              <Navbar />
              <div
                style={{
                  background: `url(${background1}) center center/cover`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center top",
                  // backgroundSize: "90rem",
                  height: "100vh",
                }}
              >
                <Container
                  className="curator-container"
                  style={{ height: "100%", paddingTop: "2rem" }}
                >
                  <Row
                    className="top"
                    style={{
                      justifyContent: "space-between",
                      maxHeight: "17rem",
                    }}
                  >
                    <Col className="left">
                      <Row
                        className="last-sold-player"
                        style={{ marginTop: "1rem" }}
                      >
                        <LastSoldPlayer player={lsPlayer} />
                      </Row>
                      <Row
                        className="most-exp-player"
                        style={{ marginTop: "1rem" }}
                      >
                        <MostExpPlayer player={msPlayer} />
                      </Row>
                    </Col>
                    <Col className="middle">
                      <>
                        <Row
                          className="last-sold-player"
                          style={{ marginTop: "1rem" }}
                        >
                          <RoundButton
                            type="button"
                            className="white-btn-custom"
                            style={{ marginRight: "1rem" }}
                            onClick={() => {
                              navigate("/curator/auction/playerlist");
                            }}
                          >
                            PLAYER LIST
                          </RoundButton>
                          <RoundButton
                            type="button"
                            className="white-btn-custom"
                            onClick={() => {
                              navigate("/curator/teamlist");
                            }}
                          >
                            TEAMS
                          </RoundButton>
                        </Row>
                        <Row
                          className="last-sold-player"
                          style={{ marginTop: "1rem" }}
                        ></Row>
                        {/* <Row
                          className="last-sold-player"
                          style={{ marginTop: "1rem" }}
                        >
                          ______________{" "}
                        </Row> */}
                        {/* <Row
                          className="last-sold-player"
                          style={{ marginTop: "1rem" }}
                        >
                          <RoundButton
                            onClick={bhideFallsBtn}
                            type="button"
                            className="blue-btn-custom"
                          >
                            BHIDE FALLS
                          </RoundButton>
                        </Row> */}

                        {/* {activeBlock?.players?.length && ( */}
                        <Row
                          className="last-sold-player"
                          style={{
                            marginTop: "1rem",
                          }}
                        >
                          <RoundButton
                            disabled={!bidStopped}
                            type="button"
                            className="blue-btn-custom"
                            onClick={onEditBidBtn}
                          >
                            EDIT BID
                          </RoundButton>
                          {activeBlock?.players?.length && (
                            <RoundButton
                              disabled={!activePlayer?.active}
                              type="button"
                              style={{ marginLeft: "1rem" }}
                              className="red-btn-custom"
                              onClick={onResetBtn}
                            >
                              RESET BID
                            </RoundButton>
                          )}
                        </Row>

                        {!activeBlock?.players?.length && (
                          <Row
                            className="last-sold-player"
                            style={{
                              marginTop: "1rem",
                            }}
                          >
                            <RoundButton
                              disabled={
                                activeBlock?.players?.length && !bidStopped
                              }
                              type="button"
                              className="blue-btn-custom"
                              onClick={onFinishedBtn}
                            >
                              FINISH BLOCK
                            </RoundButton>
                          </Row>
                        )}
                      </>
                    </Col>
                    <Col className="right">
                      {/* {!activePlayer?._id && ( */}
                      <>
                        <Row
                          className="last-sold-player"
                          style={{ marginTop: "1rem" }}
                        >
                          {activePlayer && activePlayer?._id ? (
                            <RoundButton
                              type="button"
                              style={{
                                zIndex: 999,
                                borderRadius: "0.7rem",
                              }}
                              className="blue-btn-custom"
                              onClick={onStoppedSelling}
                            >
                              {bidStopped ? "RESUME BIDDING" : "STOP BIDDING"}
                            </RoundButton>
                          ) : (
                            <>
                              {!activePlayer?._id && (
                                <RoundButton
                                  disabled={!activeBlock?.players?.length}
                                  type="button"
                                  style={{
                                    zIndex: 999,
                                    borderRadius: "0.7rem",
                                  }}
                                  className="blue-btn-custom"
                                  onClick={onCreateBid}
                                >
                                  CREATE BID
                                </RoundButton>
                              )}
                            </>
                          )}
                        </Row>
                      </>
                      {/* )} */}
                      <Row
                        className="last-sold-player"
                        style={{ marginTop: "2.5rem" }}
                      >
                        <RoundButton
                          disabled={!activePlayer.currentBid || !bidStopped}
                          onClick={onSellPlayerBtn}
                          type="button"
                          style={{ zIndex: 999 }}
                          className="sell"
                        >
                          SELL
                        </RoundButton>
                      </Row>
                      <Row
                        className="last-sold-player"
                        style={{ marginTop: "2.5rem" }}
                      >
                        <RoundButton
                          disabled={
                            (activePlayer._id
                              ? activePlayer.currentBid
                              : true) || !bidStopped
                          }
                          onClick={onUnSellBtn}
                          type="button"
                          className="unsold"
                        >
                          MOVE TO UNSELL
                        </RoundButton>
                      </Row>
                      <Row
                        className="last-sold-player"
                        style={{ marginTop: "1rem" }}
                      >
                        <RoundButton
                          disabled={
                            activePlayer._id || !activeBlock?.players?.length
                          }
                          type="button"
                          className="unsold"
                          onClick={pauseAuctionBtn}
                        >
                          PAUSE AUCTION
                        </RoundButton>
                      </Row>
                    </Col>
                  </Row>
                  <Container className="bottom">
                    <Row
                      style={{ marginTop: "2rem 0", alignItems: "flex-end" }}
                    >
                      <Col className="left">
                        <PlayerDetails player={activePlayer} isCurator={true} />
                      </Col>
                      <Col className="middle">
                        <PlayerBid player={activePlayer} />
                      </Col>
                      <Col className="right">
                        <TeamBidding player={activePlayer} />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
            </>
          ) : (
            <>
              Bidding Is Not Active!{" "}
              <Link to="/curator/playerlist">Go to Player list</Link>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CuratorPage;
