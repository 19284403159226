import "./index.scss";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ show, setShow, children, content, spannStyle, style }) => {
  return (
    <>
      <div
        className={`loading-modal mobile ${show ? "open" : "close"}`}
        style={{ ...style }}
      >
        <div
          className="spann"
          style={{
            paddingTop: content && "1rem",
            maxWidth: "30rem",
            ...spannStyle,
            position: "relative",
          }}
        >
          <CloseIcon
            onClick={() => {
              setShow(false);
            }}
            style={{
              cursor: "pointer",
              fontSize: "2rem",
              right: 10,
              top: 7,
              position: "absolute",
            }}
          />
          {children ? children : content}
        </div>
      </div>
    </>
  );
};

export default Modal;
