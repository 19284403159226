import "./index.scss";

import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const RoundButton = (props) => {
  let navigate = useNavigate();
  let { noButton } = props;
  let { children, className, to } = props;

  return (
    <Button
      {...props}
      className={
        noButton
          ? `round-nobtn-custom ${className}`
          : `round-btn-custom ${className}`
      }
    >
      {children}
    </Button>
  );
};

export default RoundButton;
