import "./index.scss";
import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UrlLoginCurator = () => {
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    // let status =
    //   localStorage.getItem("curatorToken") &&
    //   localStorage.getItem("curatorToken") !== null
    //     ? true
    //     : false;

    // let token = query.get("token");
    // if (token) localStorage.setItem("curatorToken", token);
    // if (status) {
    //   window.location.href = "/curator/auction";
    // } else {
    navigate("/curator/auction");
    // }
  }, []);

  return <></>;
};

export default UrlLoginCurator;
