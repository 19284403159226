import instance from "./instance";

const login = async (data, token) => {
  let res = await instance({
    method: "post",
    url: "login",
    data,
  });
  return res.data;
};

const getProfile = async (token) => {
  let { data } = await instance({
    method: "get",
    url: "getProfile",
  });
  return data;
};

const editProfile = async (data, token) => {
  let res = await instance({
    method: "put",
    url: "editProfile",
    data,
  });
  return res.data;
};

const changePass = async (data, token) => {
  let res = await instance({
    method: "put",
    url: "changePass",
    data,
  });
  return res.data;
};

const logout = async (token) => {
  let res = await instance({
    method: "post",
    url: "logout",
  });
  return res.data;
};

// options

const players = async (token) => {
  let res = await instance({
    method: "get",
    url: "players",
  });
  return res.data;
};

const teams = async (token) => {
  let res = await instance({
    method: "get",
    url: "teams",
  });
  return res.data;
};

const franchises = async (token) => {
  let res = await instance({
    method: "get",
    url: "franchises",
  });
  return res.data;
};

const bid = async (data, token) => {
  let res = await instance({
    method: "post",
    url: "bid",
    data,
  });
  return res.data;
};

const mostExpPlayer = async (token) => {
  let res = await instance({
    method: "get",
    url: "mostExpPlayer",
  });
  return res.data;
};

const lastSoldPlayer = async (token) => {
  let res = await instance({
    method: "get",
    url: "lastSoldPlayer",
  });
  return res.data;
};

const purseremaining = async (token) => {
  let res = await instance({
    method: "get",
    url: "purseremaining",
  });
  return res.data;
};

const activeBidding = async (token) => {
  let res = await instance({
    method: "get",
    url: "activeBidding",
  });
  return res.data;
};

const activeBlock = async (token) => {
  let res = await instance({
    method: "get",
    url: "activeBlock",
  });
  return res.data;
};

const BiddingBlocks = async (token) => {
  let res = await instance({
    method: "get",
    url: "biddingBlocks",
  });
  return res.data;
};

const startBlock = async (id, token) => {
  let res = await instance({
    method: "put",
    url: "startBlock",
    data: { id },
  });
  return res.data;
};

const finishBlock = async (data, token) => {
  let res = await instance({
    method: "put",
    url: "finishBlock",
    data,
  });
  return res.data;
};

const stopBlock = async (data, token) => {
  let res = await instance({
    method: "put",
    url: "stopBlock",
    data,
  });
  return res.data;
};

const startBidding = async (data, token) => {
  let res = await instance({
    method: "get",
    url: "startBidding",
    data,
  });
  return res.data;
};

const stopBidding = async (data, token) => {
  let res = await instance({
    method: "get",
    url: "stopBidding",
    data,
  });
  return res.data;
};

const createBid = async (data, token) => {
  let res = await instance({
    method: "put",
    url: "createBid",
    data,
  });
  return res.data;
};

const resetBid = async (data, token) => {
  let res = await instance({
    method: "put",
    url: "resetBid",
    data,
  });
  return res.data;
};

const setPlayerUnsold = async (data, token) => {
  let res = await instance({
    method: "post",
    url: "setPlayerUnsold",
    data,
  });
  return res.data;
};

const setPlayerSold = async (data, token) => {
  let res = await instance({
    method: "post",
    url: "setPlayerSold",
    data,
  });
  return res.data;
};

const setPlayerSoldCustom = async (data, token) => {
  let res = await instance({
    method: "post",
    url: "setPlayerSold/custom",
    data,
  });
  return res.data;
};

const bidStatus = async (data, token) => {
  let res = await instance({
    method: "post",
    url: "bidStatus",
    data,
  });
  return res.data;
};

const getSession = async (data, token) => {
  let res = await instance({
    method: "get",
    url: "sessions",
    data,
  });
  return res.data;
};

export default {
  login,
  getSession,
  lastSoldPlayer,
  players,
  getProfile,
  editProfile,
  resetBid,
  setPlayerSoldCustom,
  changePass,
  logout,
  franchises,
  bid,
  mostExpPlayer,
  purseremaining,
  activeBidding,
  activeBlock,
  BiddingBlocks,
  stopBidding,
  startBidding,
  bidStatus,
  teams,
  startBlock,
  finishBlock,
  stopBlock,
  createBid,
  setPlayerUnsold,
  setPlayerSold,
};
