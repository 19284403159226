import "./index.scss";
import React from "react";
import DialogBox from "../dialogBox";
import { Table } from "react-bootstrap";
import allrounder from "../../resources/logos/allrounder.png";
import Batter from "../../resources/logos/batsmen.png";
import Bowler from "../../resources/logos/bowler.png";
import Uncapped from "../../resources/logos/uncapped.png";
import Wicketkeeper from "../../resources/logos/wicketkeeper.png";
import jahaj from "../../resources/images/airplane.png";

let roleImg = (role) => {
  if (role === "Bowler") return Bowler;
  if (role === "Batter") return Batter;
  if (role === "All Rounder") return allrounder;
  if (role === "Wicketkeeper") return Wicketkeeper;
};

const ViewPlayersTable = ({ players }) => {
  let data = [
    ...players?.startedPlayers,
    ...players?.players,
    ...players?.soldPlayers,
    ...players?.unsoldPlayers,
  ];
  return (
    <div className="view-players-table">
      <Table
        responsive
        size="sm"
        style={{
          maxHeight: "2rem",
          marginBottom: "0.5rem",
        }}
      >
        <thead>
          <tr>
            <th>NAME</th>
            <th className="text-center"></th>
            <th className="text-center">BASE PRICE</th>
            <th className="text-center">SOLD AT</th>
            <th className="text-center">SOLD TO</th>
            <th className="text-center">STATUS</th>
          </tr>
        </thead>
        <tbody>
          {data[0] &&
            data?.map((player) => {
              let name = player?.name?.split(" ");
              let initial = name && name[0][0];
              let lastName = name?.slice(1, name.length)?.join(" ");

              return (
                <tr>
                  <td style={{ position: "relative" }}>
                    {initial ? `${initial}. ${String(lastName)}` : "-"}

                    {!(player.nationality.toLowerCase() === "india") && (
                      <img src={jahaj} className="overseas"></img>
                    )}
                  </td>
                  <td
                    className="text-center"
                    style={{ fontFamily: "Poppins-Light" }}
                  >
                    <img
                      src={roleImg(player?.role)}
                      style={{ maxWidth: "1.5rem" }}
                    ></img>
                  </td>
                  <td className="text-center">{player?.basePrice}L</td>
                  <td className="text-center">
                    {player.isSold ? `${player?.soldAt}L` : "-"}
                  </td>
                  <td className="text-center">
                    {player.isSold ? player?.lastBidBy?.team : "-"}
                  </td>{" "}
                  <td className="text-center">
                    {player.isSold
                      ? "SOLD"
                      : player.active
                      ? "ACTIVE"
                      : "UNSOLD"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default ViewPlayersTable;
