import "./index.scss";
import background1 from "../../resources/images/background-plain.png";
import Navbar from "../../components/navbar";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RoundButton from "../../components/roundButton";
import TeamCards from "../../components/teamCards";
import {
  selectisLoggedIn,
  selectTeamsData,
  teams as getTeams,
} from "../../store/features/curatorSlice";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const TeamList = () => {
  let dispatch = useDispatch();
  let isLoggedin = useSelector(selectisLoggedIn);
  let teams = useSelector(selectTeamsData);

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  return (
    <>
      {!isLoggedin ? (
        <>Not Logged in</>
      ) : (
        <>
          {/* <Modal
        show={showLoading}
        content="PLEASE WAIT... WHILE PLAYER IS BEING SELECTED"
      /> */}
          {/* <Navbar /> */}
          <div
            style={{
              background: `url(${background1}) center center/cover`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              // backgroundSize: "90rem",
              minHeight: "43rem",
            }}
          >
            <Container
              className="player-list-container"
              style={{ height: "100%" }}
            >
              <Row className="" style={{ justifyContent: "space-between" }}>
                {teams[0] &&
                  teams?.map((team) => (
                    <Col
                      xs={1}
                      className="table teams"
                      style={{ margin: "4rem 0 4rem 0" }}
                    >
                      <TeamCards
                        team={team?.team}
                        showDesign={true}
                        teamColor={team.color?.hex}
                        teamColorRGB={team.color?.rgb}
                        secondaryTeamColor={team?.secondaryColor}
                        secondaryTeamColorRGB={team?.secondaryColor?.rgb}
                        teamName={team.name}
                        filteredPlayerData={team.filteredPlayers}
                      />
                    </Col>
                  ))}
              </Row>
              <Row
                className=""
                style={{ padding: "1rem 0", justifyContent: "center" }}
              >
                <Link
                  style={{
                    width: "fit-content",
                    textDecoration: "none",
                    color: "inherit",
                    font: "inherit",
                    textAlign: "center",
                  }}
                  to="/curator/auction"
                >
                  <RoundButton type="button">GO BACK</RoundButton>
                </Link>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default TeamList;
