import instance from "./instance";

const mostExpPlayer = async (sessionId, token) => {
  let res = await instance({
    method: "get",
    url: "mostExpPlayer/" + sessionId,
  });
  return res.data;
};

const lastSoldPlayer = async (sessionId, token) => {
  let res = await instance({
    method: "get",
    url: "lastSoldPlayer/" + sessionId,
  });
  return res.data;
};

const purseremaining = async (sessionId, token) => {
  console.log(sessionId);
  let res = await instance({
    method: "get",
    url: "purseremaining/" + sessionId,
  });
  return res.data;
};

const activeBidding = async (sessionId, token) => {
  let res = await instance({
    method: "get",
    url: "activeBidding/" + sessionId,
  });
  return res.data;
};

const activeBlock = async (sessionId, token) => {
  let res = await instance({
    method: "get",
    url: "activeBlock/" + sessionId,
  });
  return res.data;
};

const blockStatus = async (sessionId, token) => {
  let res = await instance({
    method: "get",
    url: "blockStatus/" + sessionId,
  });
  return res.data;
};

const BiddingBlocks = async (sessionId, token) => {
  let res = await instance({
    method: "get",
    url: "biddingBlocks/" + sessionId,
  });
  return res.data;
};

export default {
  mostExpPlayer,
  lastSoldPlayer,
  purseremaining,
  activeBidding,
  activeBlock,
  BiddingBlocks,
  blockStatus,
};
