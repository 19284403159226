import "./index.scss";

import React from "react";

const BiddingPrice = ({ className, price, enabled }) => {
  return (
    <button
      style={{
        color: !enabled && "grey",
        outline: !enabled && "grey",
        borderColor: !enabled && "grey",
      }}
      className={`bidding-amount ${className}`}
    >
      <span>{price}L</span>
    </button>
  );
};

export default BiddingPrice;
