import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import navbar from "../../resources/images/navbar.png";
import { logout } from "../../store/features/bidderSlice";

import "./index.scss";

const Navbar = ({ mobile, bgcolor, content, profile }) => {
  let dispatch = useDispatch();

  return (
    <>
      {mobile ? (
        <div
          className="mobile-nav"
          style={{
            backgroundColor: bgcolor || "#fff",
          }}
        >
          {content ? (
            content
          ) : (
            <>
              <div>
                <span className="light">Hey!</span>{" "}
                {profile?.name && profile.name}
              </div>
              <Link
                className="right"
                to="#"
                onClick={async () => {
                  let res = await dispatch(logout());
                  if (res) {
                    window.location.reload();
                  }
                }}
              >
                Logout
              </Link>{" "}
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "4.5rem",
            backgroundColor: bgcolor || "#fff",
          }}
        >
          <img
            src={navbar}
            style={{
              zIndex: "999",
              height: "4.5rem",
              width: "100%",
              objectFit: "cover",
            }}
          ></img>
        </div>
      )}
    </>
  );
};

export default Navbar;
