import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Job CRUD
// export const getJobs = createAsyncThunk("jobs/getJobs", async (id, data) => {
//   const response = await jobsService.getJobsService();
//   return response;
// });

// [editJobs.fulfilled]: (state, action) => {
//   if (action.payload.statusCode === 200) {
//     let index = state.data.findIndex(
//       (ele) => ele._id === action.payload.data._id
//     );
//     state.data[index] = action.payload.data;
//   } else {
//     state.error = "Login first";
//   }
// },
// [editJobs.rejected]: (state, action) => {
//   state.error = action.error;
// },

const auctionSlice = createSlice({
  name: "auction",
  initialState: {
    isSold: false,
    status: "in progress", //other options are sold, unsold, no bids in progress;
    loadingStatus: "done", // options loading
    showLoading: true,
    error: {},
  },
  reducers: {
    logout: (state, action) => {},
  },
});

export const selectIsSold = (state) => state.auction.isSold;
export const selectSellingStatus = (state) => state.auction.status;
export const selectLoadingStatus = (state) => state.auction.loadingStatus;
export const selectShowLoading = (state) => state.auction.showLoading;

export default auctionSlice.reducer;
