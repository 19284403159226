import "./index.scss";
import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UrlLoginBidder = () => {
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    let status =
      localStorage.getItem("bidderToken") &&
      localStorage.getItem("bidderToken") !== null
        ? true
        : false;
    let token = query.get("token");
    if (token) localStorage.setItem("bidderToken", token);
    if (status) {
      window.location.href = "/bidder/bidding";
    } else {
      navigate("/bidder/bidding");
    }
  }, []);

  return <></>;
};

export default UrlLoginBidder;
