import axios from "axios";
import { backendDomain } from "../../paths";

const instance = axios.create({
  baseURL: backendDomain + "/api/v1/",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "content-type": "application/json;charset=utf-8",
  },
});

export default instance;
