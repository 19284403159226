import "./index.scss";

import React from "react";
import DialogBox from "../dialogBox";
import { Col } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  lastSoldPlayer,
  selectLastSoldPlayer,
} from "../../store/features/curatorSlice";

const LastSoldPlayer = ({ hide, player }) => {
  let name = player?.name?.split(" ");
  let initial = name && name[0][0];
  let lastName = name?.slice(1, name.length)?.join(" ");

  return (
    <DialogBox
      hide={hide}
      header={<span style={{ marginLeft: "1rem" }}>Last sold player</span>}
      headerStyle={{ justifyContent: "left", textAlign: "left" }}
      style={{ fontFamily: "Poppins-Semibold" }}
      backgroundColor={player?.lastBidBy?.franchise?.color?.hex}
    >
      <Col
        lg="5"
        className="col"
        style={{ fontSize: "12px", textAlign: "center" }}
      >
        {initial ? `${initial}. ${String(lastName)}` : "-"}
      </Col>
      <Col
        lg="2"
        className="col"
        style={{ fontSize: "12px", textAlign: "center" }}
      >
        {player?.soldAt
          ? player?.soldAt > 100
            ? `${player.soldAt / 100} CR`
            : `${player.soldAt} L`
          : "-"}
      </Col>
      <Col
        lg="5"
        className="col"
        style={{ fontSize: "12px", textAlign: "center" }}
      >
        {player?.lastBidBy?.team ? `${player?.lastBidBy?.team}` : "-"}
      </Col>
    </DialogBox>
  );
};

export default LastSoldPlayer;
