import "./index.scss";

import React from "react";
import DialogBox from "../dialogBox";
import { Col, Row } from "react-bootstrap";
// import tendua from "../../resources/images/tendua.png";
import { useSelector } from "react-redux";
import {
  selectIsSold,
  selectLoadingStatus,
  selectSellingStatus,
} from "../../store/features/auctionSlice";

import gavel from "../../resources/images/gavel.png";

const Facecam = () => {
  return (
    <Row className="dialog-box vertical" style={{ position: "relative" }}>
      <Col className="box white border"></Col>
    </Row>
  );
};

export default Facecam;
