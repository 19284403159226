import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useState } from "react";
//scss
import Style from "./dropdown1.module.scss";
import Cbtn from "../../commonbutton/cbtn";
import { height, width, fontSize, alignItems } from "@mui/system";

export default function SelectLabels(props) {
  const [age, setAge] = React.useState("Select");
  const { active, setActive, width, disabled } = props;
  const [color, setColor] = useState("");

  const handleChange = (event) =>
    setActive ? setActive(event) : setAge(event.target.value);

  React.useEffect(() => {
    switch (active) {
      case "In Progress":
        setColor("#E3B706");
        break;

      case "completed":
        setColor("#6FC70A");
        break;

      case "Ended":
        setColor("#FF0000");
        break;

      default:
        setColor("");
        break;
    }
  }, [active]);

  return (
    <div style={{ width: width ? width : "auto" }}>
      <FormControl
        className={props.className ? props.className : ""}
        sx={{
          // m: 1,
          height: height ? height : "auto",
          alignItems: alignItems ? alignItems : "auto",
          "& .MuiBox-root": {
            width: width ? width : "auto !important",
          },
          minWidth: props.minWidth ? props.minWidth : "100%",
          float: props.float ? props.float : "none",
          backgroundColor: props.selectBg ? props.selectBg : "#E9E9E9",
          borderRadius: "10px",
          border: props.MSelect + "!important" ? props.MSelect : "8px",
          // padding:"9px 14px",
          "& .MuiInputBase-formControl": {
            fontSize: fontSize ? fontSize : "auto",
            fontFamily: "Poppins-Regular",
            borderRadius: "none",
          },
          "& .MuiPopover-paper": {
            boxShadow: "0px 5px 14px #00000029",
          },
        }}
      >
        <Select
          disabled={disabled}
          MenuProps={{
            variant: "menu",
            MenuListProps: {
              sx: {
                // border: "50px solid black",
                // outline:"20px solid red"
                // background:"red"
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              },
            },
          }}
          value={active?.option || age}
          onChange={handleChange}
          displayEmpty
          inputProps={{
            className: "",
            "aria-label": "Without label",
            sx: {
              height: "100px",
              color: color ? color + " !important" : "auto",
            },
          }}
          sx={{
            width: width ? width : "auto",

            "& 	.MuiSelect-select": {
              padding: "9.5px 14px",
            },
            "& .MuiInputBase-input-MuiOutlinedInput-input": {
              fontFamily: "Poppins-Regular",
              color: "#363b64",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              padding: "9.5px 14px",
            },
            fontSize: "12px",
            "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
              backgroundColor: "blue",
            },
          }}
        >
          <MenuItem
            disableRipple
            disabled
            sx={{
              fontFamily: "Poppins-Regular",
              color: "#363b64",
              fontSize: "14px",
              "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                backgroundColor: "blue",
              },
            }}
            value={active?.option || age}
          >
            {active?.option || age}
          </MenuItem>

          {props?.slist?.map((e, i) => {
            if (e.option && e.value) {
              if (e.option === "completed") {
                return (
                  <MenuItem
                    disableRipple
                    sx={{
                      fontFamily: "Poppins-Medium",
                      color: "#6FC70A",
                      fontSize: "14px",
                      "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "blue",
                      },
                    }}
                    value={e}
                    key={i}
                  >
                    {e.option}
                  </MenuItem>
                );
              } else if (e.option === "In Progress") {
                return (
                  <MenuItem
                    disableRipple
                    sx={{
                      fontFamily: "Poppins-Medium",
                      color: "#E3B706",
                      fontSize: "14px",
                      "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "blue",
                      },
                    }}
                    value={e}
                    key={i}
                  >
                    {e.option}
                  </MenuItem>
                );
              } else if (e.option === "Ended") {
                return (
                  <MenuItem
                    disableRipple
                    sx={{
                      fontFamily: "Poppins-Medium",
                      color: "#FF0000",
                      fontSize: "14px",
                      "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "blue",
                      },
                    }}
                    value={e}
                    key={i}
                  >
                    {e.option}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    disableRipple
                    sx={{
                      fontFamily: "Poppins-Medium",
                      color: "#363b64",
                      fontSize: "14px",
                      "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "blue",
                      },
                    }}
                    value={e?.value}
                    key={i}
                  >
                    {e?.option}
                  </MenuItem>
                );
              }
            } else {
              return null;
            }
          })}
        </Select>
      </FormControl>
    </div>
  );
}
