import React from "react";
import { Row } from "react-bootstrap";
import "./index.scss";

const DialogBox = ({
  style,
  headerStyle,
  header,
  children,
  content,
  backgroundColor,
  showDesign,
  hide,
  className,
  logo,
  logoSmall,
  wrapperStyle,
  secondaryBackgroundColor,
}) => {
  let contentbg = showDesign ? "white" : backgroundColor;
  let headerBg = !showDesign ? "white" : backgroundColor;
  return (
    <div
      className={`dialog-box ${className}`}
      style={{
        ...wrapperStyle,
        position: "relative",
        zIndex: "23",
      }}
    >
      {logoSmall && (
        <img
          src={logoSmall}
          alt={`team ${logoSmall}`}
          style={{
            position: "absolute",
            zIndex: 999,
            left: "-2.2rem",
            top: "-1rem",
            height: "4.4rem",
          }}
        />
      )}
      {logo && (
        <Row className="box image">
          {logo && (
            <img
              src={logo}
              alt={`Not Bidded`}
              style={{
                color: "white",
                maxHeight: "10rem",
                width: "fit-content",
              }}
            />
          )}
        </Row>
      )}

      <Row
        className={`box white header`}
        style={{
          textAlign: "center",
          ...headerStyle,
          backgroundColor: headerBg,
        }}
      >
        {showDesign && (
          <div
            class="circular-progress-circle"
            style={{ backgroundColor: secondaryBackgroundColor || "red" }}
          />
        )}
        <span style={{ overflow: "none", zIndex: "60" }}>{header}</span>
      </Row>

      <Row
        className={`box blue ${hide ? "hide" : ""}`}
        style={{
          ...style,
          backgroundColor: contentbg,
        }}
      >
        {content ? (
          <>
            <span>{content}</span>
          </>
        ) : (
          <>{children}</>
        )}
      </Row>
    </div>
  );
};

export default DialogBox;
