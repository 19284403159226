import "./index.scss";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const Modal = ({ show, setShow, style, children, content, spanStyle }) => {
  return (
    <div style={{ ...style }}>
      {show && (
        <div className={`loading-modal ${show ? "open" : "close"}`}>
          <div style={{ ...spanStyle }} className="spann">
            <CloseIcon
              onClick={() => {
                setShow(false);
              }}
              style={{
                cursor: "pointer",
                fontSize: "2rem",
                right: 10,
                top: 7,
                position: "absolute",
              }}
            />
            {children ? children : content}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
