import "./index.scss";
import background1 from "../../resources/images/background-plain.png";
import Navbar from "../../components/navbar";
import DialogBox from "../../components/dialogBox";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal/mobile";
import TeamCards from "../../components/teamCards";

import gavel from "../../resources/images/gavel2.png";
import depressed from "../../resources/images/depressed.png";

import backArrow from "../../resources/images/Arrow 1.png";
import jahaj from "../../resources/images/airplane.png";
import cap from "../../resources/logos/uncapped.png";
import { useEffect, useState } from "react";
import {
  boughtPlayers,
  selectBoughtPlayerData,
  selectFilteredPlayers,
  purseremaining as getPurse,
  selectisLoggedIn,
  selectTeamName,
  activeBidding,
  blockStatus,
  selectactiveBidding,
  selectBidderProfile,
} from "../../store/features/bidderSlice";
import { Link } from "react-router-dom";
import {
  getActiveSession,
  selectActiveSession,
} from "../../store/features/curatorSlice";

const ViewAllPlayers = ({ socket }) => {
  let [showLoading, setShowLoading] = useState(false);
  let [counter, setCounter] = useState(1);
  let [content, setContent] = useState("");
  let isLoggedIn = useSelector(selectisLoggedIn);
  let playersData = useSelector(selectBoughtPlayerData);
  let filteredPlayerData = useSelector(selectFilteredPlayers);
  let profile = useSelector(selectBidderProfile);
  let teamName = useSelector(selectTeamName);
  let teamColor = playersData?.Franchise?.color || { hex: "#0040A8" };
  let secondaryTeamColor = playersData?.Franchise?.secondaryColor || {
    hex: "red",
  };

  let session = useSelector(selectActiveSession);
  let activePlayer = useSelector(selectactiveBidding);
  let dispatch = useDispatch();

  const brightness = Math.round(
    (parseInt(teamColor?.rgb?.r) * 299 +
      parseInt(teamColor?.rgb?.g) * 587 +
      parseInt(teamColor?.rgb?.b) * 114) /
      1000
  );

  console.log(playersData?.Franchise?.secondaryColor);

  useEffect(() => {
    if (profile._id && session?.sessionId) {
      socket.emit("bidder-entry", profile._id);
      socket.on("bidder-connected", () => {
        socket.emit("join-bidder-session", session?.sessionId);
      });
    }
  }, [profile._id]);

  useEffect(() => {
    dispatch(boughtPlayers());
    dispatch(activeBidding());
    dispatch(getPurse());
    dispatch(boughtPlayers());
    dispatch(getActiveSession());
    dispatch(blockStatus());
  }, [counter]);

  useEffect(() => {
    socket.on("block-started", (playerType) => {
      setContent(playerType?.toUpperCase() + " BLOCK STARTED!");
      setShowLoading(true);
      setCounter((a) => a + 1);
      setTimeout(() => {
        setShowLoading(false);
      }, 2500);
    });

    socket.on("block-paused", () => {
      setContent("BIDDING STOPPED!");
      setShowLoading(true);
      setCounter((a) => a + 1);
      setTimeout(() => {
        setShowLoading(false);
      }, 2500);
    });

    socket.on("block-finished", (playerType) => {
      setShowLoading(true);
      // console.log(playerType);
      setContent(playerType?.toUpperCase() + " BLOCK FINISHED!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });

    socket.on("bid-updated", () => {
      setCounter((a) => a + 1);
    });

    socket.on("rtm-updated", () => {
      setCounter((a) => a + 1);
    });

    socket.on("set-player-unsold", () => {
      setContent(
        <>
          <img src={depressed} style={{ height: "13rem" }} />
          <span
            style={{
              color: "#920202",
              fontSize: "2rem",
            }}
          >
            {" "}
            UNSOLD
          </span>
        </>
      );
      setShowLoading(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      setCounter((a) => a + 1);
    });

    socket.on("set-player-sold", () => {
      // console.log(activePlayer);
      setShowLoading(true);
      setContent(
        <>
          <img src={gavel} style={{ height: "10rem" }} />
          <div style={{ color: "#0BD18A" }}> SOLD TO</div>
          <div>{activePlayer?.lastBidBy?.team}</div>
        </>
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    });
  }, []);

  return (
    <>
      {!isLoggedIn ? (
        <>
          Open with the provided link or <Link to="/curator/login">LOGIN</Link>
        </>
      ) : (
        <div
          className="main"
          style={{
            overflow: "hidden",
            background: `url(${background1}) center center/cover`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            // backgroundSize: "90rem",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <Modal isMobile={true} show={showLoading} content={content} />
          <Navbar
            mobile={true}
            content={
              <Link
                style={{ textDecoration: "none", font: "inherit" }}
                to="/bidder"
              >
                <img src={backArrow} />
                <span style={{ marginLeft: "1rem", color: "#0040A8" }}>
                  BACK
                </span>
              </Link>
            }
          />
          <div
            className="main"
            style={{
              paddingTop: "2.3rem",
              background: `url(${background1}) center center/cover`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              // backgroundSize: "90rem",
              // height: "43rem",
            }}
          >
            <Container className="user-container">
              {/* <Row
                className=""
                style={{
                  margin: "1rem 0",
                  justifyContent: "flex-start",
                  width: "90%",
                  color: "white",
                  fontFamily: "Poppins-Regular",
                  fontSize: "0.7rem",
                }}
              >
                <Col xs="5" style={{ margin: "4px 0" }}>
                  <img
                    src={jahaj}
                    style={{
                      height: "1.2rem",
                      margin: "0 4px",
                      padding: 0,
                    }}
                  ></img>
                  Overseas
                </Col>
                <Col
                  xs="7"
                  style={{ margin: "6px 0", textAlign: "left", padding: 0 }}
                >
                  <img
                    src={jahaj}
                    style={{
                      height: "1.2rem",
                      margin: "0 4px",
                      padding: 0,
                    }}
                  ></img>
                  <img
                    src={cap}
                    style={{
                      height: "1.2rem",
                      margin: "0 4px",
                      padding: 0,
                    }}
                  ></img>
                  Uncapped Overseas
                </Col>
                <Col xs="6" style={{ margin: "6px 0" }}>
                  <img
                    src={cap}
                    style={{
                      height: "1.2rem",
                      margin: "0 4px",
                      padding: 0,
                    }}
                  ></img>
                  Uncapped
                </Col>
              </Row> */}

              <Row
                className=""
                style={{
                  marginTop: "2rem",
                  minWidth: "25rem",
                  justifyContent: "center",
                  width: "85%",
                  backgroundColor: teamColor?.hex,
                }}
              >
                <Col
                  className="team-details team-details-1"
                  style={{
                    backgroundColor: teamColor?.hex,
                  }}
                >
                  <DialogBox
                    backgroundColor={teamColor?.hex}
                    wrapperStyle={{ minWidth: 0 }}
                    header="Minimum Criteria"
                    className="player-tables-1"
                    headerStyle={{
                      fontFamily: "Poppins-Regular",
                      // paddingLeft: "2rem",
                      textAlign: "center",
                      fontSize: "0.8rem",
                    }}
                  >
                    <Table
                      size="sm"
                      style={{
                        width: "95%",
                        color: brightness < 125 ? "#F9F8F8" : "black",

                        // color: "#F9F8F8",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              border: "none",
                              fontSize: "0.7rem",
                              fontFamily: "Poppins-Regular",
                              verticalAlign: "middle",
                            }}
                          >
                            Overseas
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "none",
                              fontSize: "0.8rem",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            {filteredPlayerData?.Overseas
                              ? filteredPlayerData.Overseas.length
                              : 0}
                            /5
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              border: "none",
                              fontSize: "0.7rem",
                              fontFamily: "Poppins-Regular",
                              verticalAlign: "middle",
                            }}
                          >
                            Indians
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "none",
                              fontSize: "0.8rem",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            {filteredPlayerData?.Indians
                              ? filteredPlayerData.Indians.length
                              : 0}
                            /11
                          </td>
                        </tr>
                        {/* <tr>
                          <td
                            style={{
                              textAlign: "left",
                              border: "none",
                              fontSize: "0.7rem",
                              fontFamily: "Poppins-Regular",
                              verticalAlign: "middle",
                            }}
                          >
                            Uncapped
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "none",
                              fontSize: "0.8rem",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            {filteredPlayerData?.Uncapped
                              ? filteredPlayerData.Uncapped.length
                              : 0}
                            /1
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </DialogBox>
                </Col>
                <Col className="team-details team-details-2">
                  <DialogBox
                    backgroundColor={teamColor?.hex}
                    wrapperStyle={{ minWidth: 0 }}
                    header={`Your team ${
                      playersData?.players?.[0]
                        ? playersData?.players?.length
                        : 0
                    }/20`}
                    className="player-tables-2"
                    headerStyle={{
                      fontFamily: "Poppins-Regular",
                      // paddingRight: "2rem",
                      textAlign: "center",
                      fontSize: "0.8rem",
                    }}
                  >
                    <Table
                      size="sm"
                      style={{
                        width: "95%",
                        color: brightness < 125 ? "#F9F8F8" : "black",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              border: "none",
                              fontSize: "0.7rem",
                              fontFamily: "Poppins-Regular",
                              verticalAlign: "middle",
                            }}
                          >
                            Batters
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "none",
                              fontSize: "0.8rem",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            {filteredPlayerData?.Batter
                              ? filteredPlayerData.Batter.length
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              border: "none",
                              fontSize: "0.7rem",
                              fontFamily: "Poppins-Regular",
                              verticalAlign: "middle",
                            }}
                          >
                            Bowlers
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "none",
                              fontSize: "0.8rem",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            {filteredPlayerData?.Bowler
                              ? filteredPlayerData.Bowler.length
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              border: "none",
                              fontSize: "0.7rem",
                              fontFamily: "Poppins-Regular",
                              verticalAlign: "middle",
                            }}
                          >
                            WK
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "none",
                              fontSize: "0.8rem",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            {filteredPlayerData?.Wicketkeeper
                              ? filteredPlayerData.Wicketkeeper.length
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              border: "none",
                              fontSize: "0.7rem",
                              fontFamily: "Poppins-Regular",
                              verticalAlign: "middle",
                            }}
                          >
                            All Rounders
                          </td>
                          <td
                            className="text-center"
                            style={{
                              border: "none",
                              fontSize: "0.8rem",
                              fontFamily: "Poppins-Regular",
                            }}
                          >
                            {filteredPlayerData["All Rounder"]
                              ? filteredPlayerData["All Rounder"].length
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </DialogBox>
                </Col>
              </Row>
              <Row
                className=""
                style={{
                  justifyContent: "center",
                  width: "85%",
                  marginTop: "2rem",
                  maxWidth: "25rem",
                  backgroundColor: teamColor?.hex,
                }}
              >
                <Col className="team-details viewAllPlayers ">
                  <TeamCards
                    team={playersData?.Franchise}
                    isMobile={true}
                    showDesign={true}
                    teamColorRGB={teamColor}
                    secondaryTeamColorRGB={secondaryTeamColor}
                    brightness={brightness}
                    teamColor={teamColor?.hex}
                    secondaryTeamColor={secondaryTeamColor?.hex}
                    filteredPlayerData={filteredPlayerData}
                    teamName={teamName}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewAllPlayers;
