import "./index.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/navbar";
import {
  boughtPlayers,
  getProfile,
  activeBlock as activeBlockk,
  BiddingBlocks,
  blockStatus,
  selectbiddingBlocks,
  purseremaining as getPurse,
  selectBidderProfile,
  selectactiveBidding,
  activeBidding,
  selectActiveSession,
  getActiveSession,
  selectLoginStatus,
} from "../../store/features/bidderSlice";
import { Col, Container, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import RoundButton from "../../components/roundButton";
import backArrow from "../../resources/images/Arrow 1.png";
import background1 from "../../resources/images/background-plain.png";
import ViewPlayers from "../../components/viewPlayers";
import Modal from "../../components/modal";
import gavel from "../../resources/images/gavel2.png";
import rotate from "../../resources/videos/rotate your phone.gif";
import depressed from "../../resources/images/depressed.png";
import { CircularProgress } from "@mui/material";

const AllPlayers = ({ socket }) => {
  let [key, setKey] = useState("elite");
  let biddingBlocks = useSelector(selectbiddingBlocks);
  let [showLoading, setShowLoading] = useState(false);
  let [counter, setCounter] = useState(1);
  let [content, setContent] = useState("");
  let profile = useSelector(selectBidderProfile);
  let session = useSelector(selectActiveSession);
  let activePlayer = useSelector(selectactiveBidding);
  let LoginStatus = useSelector(selectLoginStatus);
  let dispatch = useDispatch();

  let [screenOrientation, setScreenOrientation] = useState("portrait");

  let isPortraitMode = () => {
    return screenOrientation === "portrait";
  };

  let setOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setScreenOrientation("portrait");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      setScreenOrientation("landscape");
    }
  };

  window.addEventListener("orientationchange", () => {
    setScreenOrientation(window.screen.orientation.type?.split("-")[0]);
  });

  useEffect(() => {
    setScreenOrientation(window.screen.orientation.type?.split("-")[0]);
  });

  useEffect(() => {
    if (profile?._id && session?.sessionId) {
      socket.emit("bidder-entry", profile?._id);
      socket.on("bidder-connected", () => {
        socket.emit("join-bidder-session", session?.sessionId);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(boughtPlayers());
    dispatch(getProfile());
    dispatch(activeBidding());
    dispatch(getPurse());
    dispatch(activeBlockk());
    dispatch(BiddingBlocks());
    dispatch(getActiveSession());
    dispatch(boughtPlayers());
    dispatch(blockStatus());
  }, [counter]);

  useEffect(() => {
    if (session?.sessionId) {
      socket.on("block-started", (playerType) => {
        setContent(playerType?.toUpperCase() + " BLOCK STARTED!");
        setShowLoading(true);
        setCounter((a) => a + 1);
        setTimeout(() => {
          setShowLoading(false);
        }, 2500);
      });

      socket.on("block-paused", () => {
        setContent("BIDDING STOPPED!");
        setShowLoading(true);
        setCounter((a) => a + 1);
        setTimeout(() => {
          setShowLoading(false);
        }, 2500);
      });

      socket.on("block-finished", (playerType) => {
        setShowLoading(true);
        // console.log(playerType);
        setContent(playerType?.toUpperCase() + " BLOCK FINISHED!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });

      socket.on("bid-updated", () => {
        setCounter((a) => a + 1);
      });

      socket.on("rtm-updated", () => {
        setCounter((a) => a + 1);
      });

      socket.on("set-player-unsold", () => {
        setContent(
          <>
            <img src={depressed} style={{ height: "13rem" }} />
            <span
              style={{
                color: "#920202",
                fontSize: "2rem",
              }}
            >
              {" "}
              UNSOLD
            </span>
          </>
        );
        setShowLoading(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setCounter((a) => a + 1);
      });

      socket.on("set-player-sold", () => {
        // console.log(activePlayer);
        setShowLoading(true);
        setContent(
          <>
            <img src={gavel} style={{ height: "10rem" }} />
            <div style={{ color: "#0BD18A" }}> SOLD TO</div>
            <div>{activePlayer?.lastBidBy?.team}</div>
          </>
        );
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
    }
  }, [session?.sessionId]);

  return (
    <>
      {LoginStatus === "waiting" && (
        <>
          {
            <CircularProgress
              sx={{
                ".MuiCircularProgress-svg": {
                  // height: "6rem",
                  // width: "6rem",
                },
                position: "absolute",
                top: "40%",
                left: "47%",
                height: "4rem !important",
                width: "4rem !important",
              }}
            />
          }
        </>
      )}

      {LoginStatus === "failed" && (
        <>
          Not Authenticated. <Link to="/bidder/login">Login</Link>
          <Navigate to="/bidder/login" replace></Navigate>
        </>
      )}

      {LoginStatus === "success" && (
        <>
          {isPortraitMode() ? (
            <>
              <Modal show={showLoading} content={content} />
              <Navbar
                mobile={true}
                content={
                  <Link
                    style={{ textDecoration: "none", font: "inherit" }}
                    to="/bidder"
                  >
                    <img src={backArrow} />
                    <span style={{ marginLeft: "1rem", color: "#0040A8" }}>
                      BACK
                    </span>
                  </Link>
                }
              />
              <Container className="player-list-container">
                <Row
                  className=""
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <Col
                    className="table"
                    style={{
                      fontFamily: "Poppins-Semibold",
                      fontSize: "2rem",
                      textAlign: "center",
                      marginTop: "3rem",
                      height: "50%",
                      color: "white",
                      width: "112%",
                    }}
                  >
                    Auction Pool
                    <img
                      style={{
                        // height: "100%",
                        width: "100%",
                        mixBlendMode: "lighten",
                      }}
                      src={rotate}
                    ></img>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <div id="view-player-mobile">
              <Modal show={showLoading} content={content} />
              <Navbar
                mobile={true}
                content={
                  <Link
                    style={{ textDecoration: "none", font: "inherit" }}
                    to="/bidder"
                  >
                    <img src={backArrow} />
                    <span style={{ marginLeft: "1rem", color: "#0040A8" }}>
                      BACK
                    </span>
                  </Link>
                }
              />
              <div
                style={{
                  paddingTop: "2rem",
                  background: `url(${background1}) center center/cover`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center top",
                  // backgroundSize: "90rem",
                  minHeight: "100vh",
                }}
              >
                <Container className="player-list-container">
                  <Row
                    className=""
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <Col
                      className="table"
                      style={{
                        height: "50%",
                        width: "112%",
                      }}
                    >
                      <ViewPlayers
                        blocks={biddingBlocks}
                        keyState={{ key, setKey }}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AllPlayers;
